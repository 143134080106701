/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GoogleLoginRequest } from '../models/GoogleLoginRequest';
import type { LoginRequest } from '../models/LoginRequest';
import type { User } from '../models/User';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthService {

    /**
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static postApiAuthSignUp(
requestBody?: LoginRequest,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/sign-up',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static postApiAuthLogin(
requestBody?: LoginRequest,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/login',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static postApiAuthGoogleLogin(
requestBody?: GoogleLoginRequest,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/google-login',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns User Success
     * @throws ApiError
     */
    public static getApiAuthProfile(): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auth/profile',
        });
    }

}
