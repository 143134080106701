import { Alert, IconButton, Typography } from "@mui/material";
import React from "react";
import "./LoginInput.scss";

interface LoginInputProps {
  value: string;
  onChange: (text: string) => void;
  icon?: React.ReactNode;
  onIconClick?: () => void;
  name: string;
  type: string;
  isError?: boolean;
  errorText?: string;
  autofocus?: boolean;
}

export const LoginInput = (props: LoginInputProps) => {
  return (
    <div className="login-input-wrapper">
      <Typography className="login-input-name">{props.name}</Typography>
      <div className="login-input-container">
        {props.type != "text-area" ? (
          <input
            className="login-input"
            type={props.type}
            onChange={(e) => props.onChange(e.target.value)}
            value={props.value}
            autoFocus={props.autofocus}
          />
        ) : (
          <textarea
            className="login-text-area"
            onChange={(e) => props.onChange(e.target.value)}
            value={props.value}
          />
        )}

        <IconButton
          className={"login-icon " + (props.value?.length ? "active" : "")}
          onClick={props.onIconClick}
        >
          {props.icon}
        </IconButton>
      </div>
      {props.isError && (
        <Alert className="login-error" severity="error">
          {props.errorText}
        </Alert>
      )}
    </div>
  );
};
