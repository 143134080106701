import { getDateValue } from "../../../utils/dateTimeUtil";
import { ListItemCategoryInternalModel } from "../../internalStorage/models/ListItemCategoryInternalModel";
import { ListItemPromptInternalModel } from "../../internalStorage/models/ListItemPromptInternalModel";
import { InternalStorageCategoriesService } from "../../internalStorage/services/InternalStorageCategoriesService";
import { InternalStoragePromptsService } from "../../internalStorage/services/InternalStoragePromptsService";
import {
  CategoriesService,
  CategoryDto,
  ItemPromptDto,
  ItemPromptsService,
  UserCategoriesService,
  UserItemPromptsService,
} from "../../openapi";

import { SequentialTaskRunner } from "./SequentialTaskRunner";

const isCategorySynced = (
  serverCategory: CategoryDto | undefined,
  localCategory: ListItemCategoryInternalModel | undefined,
) =>
  localCategory?.id &&
  serverCategory?.updated &&
  localCategory?.updated &&
  getDateValue(serverCategory.updated) === getDateValue(localCategory.updated);

export class SyncPromptsService {
  private static turnedOn = false;
  private static sequentialTaskRunner = new SequentialTaskRunner();
  private static updateStore: (() => void) | undefined;
  private static checkSignIn: (() => boolean) | undefined;

  private static async saveServerPromptsToLocal(
    serverPrompts: ItemPromptDto[],
    localPromptsNames: Set<string>,
    serverCategories: CategoryDto[],
    localCategories: ListItemCategoryInternalModel[],
  ): Promise<void> {
    await InternalStoragePromptsService.addOrUpdatePrompts(
      serverPrompts
        .map((serverPrompt: ItemPromptDto): ListItemPromptInternalModel | undefined => {
          const isOnLocal: boolean = localPromptsNames.has(serverPrompt.name);
          const localCategory: ListItemCategoryInternalModel | undefined =
            localCategories.find(
              (localCategory: ListItemCategoryInternalModel): boolean =>
                localCategory.id === serverPrompt.categoryId,
            );
          const serverCategory = serverCategories.find(
            (serverCategory: CategoryDto): boolean =>
              serverCategory.id === serverPrompt.categoryId,
          );

          if (
            !isOnLocal &&
            localCategory &&
            isCategorySynced(serverCategory, localCategory)
          ) {
            return {
              localId: serverPrompt.name,
              name: serverPrompt.name,
              order: serverPrompt.order,
              localCategoryId: localCategory.localId,
              created: serverPrompt.created,
              updated: serverPrompt.updated,
            };
          }
        })
        .filter(
          (
            prompt: ListItemPromptInternalModel | undefined,
          ): prompt is ListItemPromptInternalModel => prompt !== undefined,
        ),
    );
  }

  private static async postLocalPromptsToServer(
    serverPromptsNames: Set<string>,
    localPrompts: ListItemPromptInternalModel[],
    serverCategories: CategoryDto[],
    localCategories: ListItemCategoryInternalModel[],
  ): Promise<void> {
    await Promise.all(
      localPrompts.map(
        async (localPrompt: ListItemPromptInternalModel): Promise<void> => {
          const isOnServer: boolean = serverPromptsNames.has(localPrompt.name);
          const localCategory: ListItemCategoryInternalModel | undefined =
            localCategories.find(
              (localCategory: ListItemCategoryInternalModel): boolean =>
                localCategory.localId === localPrompt.localCategoryId,
            );
          const serverCategory = serverCategories.find(
            (serverCategory: CategoryDto): boolean =>
              serverCategory.id === localCategory?.id,
          );

          if (
            !isOnServer &&
            serverCategory &&
            isCategorySynced(serverCategory, localCategory)
          ) {
            await UserItemPromptsService.postApiUserItemPrompts({
              name: localPrompt.name,
              order: localPrompt.order,
              categoryId: serverCategory.id,
            });
          }
        },
      ),
    );
  }

  private static async updateLocalPrompts(
    serverPrompts: ItemPromptDto[],
    localPrompts: ListItemPromptInternalModel[],
    serverCategories: CategoryDto[],
    localCategories: ListItemCategoryInternalModel[],
  ): Promise<void> {
    await InternalStoragePromptsService.addOrUpdatePrompts(
      localPrompts
        .map(
          (
            localPrompt: ListItemPromptInternalModel,
          ): ListItemPromptInternalModel | undefined => {
            const serverPrompt: ItemPromptDto | undefined = serverPrompts.find(
              (serverPrompt: ItemPromptDto): boolean =>
                serverPrompt.name === localPrompt.name,
            );
            if (!serverPrompt?.updated || !localPrompt.updated) {
              return;
            }
            const localCategory: ListItemCategoryInternalModel | undefined =
              localCategories.find(
                (localCategory: ListItemCategoryInternalModel): boolean =>
                  localCategory.id === serverPrompt.categoryId,
              );
            const serverCategory = serverCategories.find(
              (serverCategory: CategoryDto): boolean =>
                serverCategory.id === serverPrompt.categoryId,
            );
            const serverPromptUpdated: number = new Date(serverPrompt.updated).valueOf();
            const localPromptUpdated: number = new Date(localPrompt.updated).valueOf();
            const isServerPromptNewer: boolean =
              serverPromptUpdated - localPromptUpdated > 0;

            if (
              isServerPromptNewer &&
              localCategory &&
              isCategorySynced(serverCategory, localCategory)
            ) {
              return {
                localId: localPrompt.localId,
                name: serverPrompt.name,
                order: serverPrompt.order,
                localCategoryId: localCategory.localId,
                created: serverPrompt.created,
                updated: serverPrompt.updated,
              };
            }
          },
        )
        .filter(
          (
            prompt: ListItemPromptInternalModel | undefined,
          ): prompt is ListItemPromptInternalModel => prompt !== undefined,
        ),
    );
  }

  private static async updateServerPrompts(
    serverPrompts: ItemPromptDto[],
    localPrompts: ListItemPromptInternalModel[],
    serverCategories: CategoryDto[],
    localCategories: ListItemCategoryInternalModel[],
  ): Promise<void> {
    await Promise.all(
      serverPrompts.map(async (serverPrompt: ItemPromptDto): Promise<void> => {
        const localPrompt: ListItemPromptInternalModel | undefined = localPrompts.find(
          (localPrompt: ListItemPromptInternalModel): boolean =>
            localPrompt.name === serverPrompt.name,
        );
        if (!localPrompt?.updated || !localPrompt.updated) {
          return;
        }
        const localCategory: ListItemCategoryInternalModel | undefined =
          localCategories.find(
            (localCategory: ListItemCategoryInternalModel): boolean =>
              localCategory.localId === localPrompt.localCategoryId,
          );
        const serverCategory = serverCategories.find(
          (serverCategory: CategoryDto): boolean =>
            serverCategory.id === localCategory?.id,
        );
        const serverPromptUpdated: number = new Date(serverPrompt.updated).valueOf();
        const localPromptUpdated: number = new Date(localPrompt.updated).valueOf();
        const isLocalPromptNewer: boolean = localPromptUpdated - serverPromptUpdated > 0;

        if (
          isLocalPromptNewer &&
          serverCategory &&
          isCategorySynced(serverCategory, localCategory)
        ) {
          if (serverPrompt.isCustom) {
            await UserItemPromptsService.putApiUserItemPrompts({
              name: localPrompt.name,
              order: localPrompt.order,
              categoryId: serverCategory.id,
            });
          } else {
            await UserItemPromptsService.postApiUserItemPrompts({
              name: localPrompt.name,
              order: localPrompt.order,
              categoryId: serverCategory.id,
            });
          }
        }
      }),
    );
  }

  private static async syncPrompts(): Promise<void> {
    const localPrompts: ListItemPromptInternalModel[] =
      await InternalStoragePromptsService.getPrompts();
    const localPromptsNames: Set<string> = new Set(
      localPrompts.map((localPrompt: ListItemPromptInternalModel) => localPrompt.name),
    );
    const localCategories: ListItemCategoryInternalModel[] =
      await InternalStorageCategoriesService.getCategories();
    const isSignedIn = this.checkSignIn && this.checkSignIn();

    if (isSignedIn) {
      const serverPrompts: ItemPromptDto[] =
        await UserItemPromptsService.getApiUserItemPrompts();
      const serverPromptsNames: Set<string> = new Set(
        serverPrompts.map((serverPrompt: ItemPromptDto) => serverPrompt.name),
      );
      const serverCategories: CategoryDto[] =
        await UserCategoriesService.getApiUserCategories();
      await this.saveServerPromptsToLocal(
        serverPrompts,
        localPromptsNames,
        serverCategories,
        localCategories,
      );
      await this.postLocalPromptsToServer(
        serverPromptsNames,
        localPrompts,
        serverCategories,
        localCategories,
      );
      await this.updateLocalPrompts(
        serverPrompts,
        localPrompts,
        serverCategories,
        localCategories,
      );
      await this.updateServerPrompts(
        serverPrompts,
        localPrompts,
        serverCategories,
        localCategories,
      );
    } else {
      const serverPrompts: ItemPromptDto[] = await ItemPromptsService.getApiItemPrompts();
      const serverCategories: CategoryDto[] = await CategoriesService.getApiCategories();
      await this.saveServerPromptsToLocal(
        serverPrompts,
        localPromptsNames,
        serverCategories,
        localCategories,
      );
    }
  }

  public static enqueue(): void {
    this.sequentialTaskRunner.enqueue(async () => {
      console.time("Prompts synced");
      console.log("Prompts Synchronization is running...");
      await this.syncPrompts();
      if (this.updateStore) {
        this.updateStore();
      }
      console.timeEnd("Prompts synced");
    });
  }

  public static run(
    interval: number,
    updateStore: () => void,
    checkSignIn: () => boolean,
  ): void {
    if (!this.turnedOn) {
      this.updateStore = updateStore;
      this.checkSignIn = checkSignIn;
      setInterval(() => this.enqueue(), interval);
      this.turnedOn = true;
    }
  }
}
