import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { BeforeInstallPromptEvent } from "pwa-install-handler";
import React, { useState, useEffect } from "react";

import bucketImage from "../../../assets/img/bucket.svg";
import { LOCAL_STORAGE_KEYS } from "../../../utils/constants";
import "./InstallPwaDialog.scss";

interface InstallPwaDialogProps {
  signedIn: boolean;
  interval: number;
  delay: number;
}

export const InstallPwaDialog = (props: InstallPwaDialogProps) => {
  const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent | null>(
    null,
  );
  const [isDialogOpened, setIsDialogOpened] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (event: BeforeInstallPromptEvent) => {
      event.preventDefault();
      if (!props.signedIn) {
        return;
      }
      setDeferredPrompt(event);
      const currentTime = Date.now();
      const lastShowTime = localStorage.getItem(
        LOCAL_STORAGE_KEYS.INSTALL_BUTTON_LAST_SHOW,
      );
      if (!lastShowTime || currentTime - parseInt(lastShowTime, 10) > props.interval) {
        setTimeout(() => {
          setIsDialogOpened(true);
          localStorage.setItem(
            LOCAL_STORAGE_KEYS.INSTALL_BUTTON_LAST_SHOW,
            Date.now().toString(),
          );
        }, props.delay);
      }
    };

    const handleAppInstalled = () => {
      setDeferredPrompt(null);
      setIsDialogOpened(false);
    };

    window.addEventListener(
      "beforeinstallprompt",
      handleBeforeInstallPrompt as EventListener,
    );
    window.addEventListener("appinstalled", handleAppInstalled as EventListener);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt as EventListener,
      );
      window.removeEventListener("appinstalled", handleAppInstalled as EventListener);
    };
  }, [props.signedIn, props.interval, props.delay]);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      await deferredPrompt.prompt();
      setIsDialogOpened(false);
      setDeferredPrompt(null);
    }
  };

  return (
    <Dialog open={isDialogOpened} onClose={() => setIsDialogOpened(false)}>
      <DialogTitle className="install-pwa-title">
        Нравится приложение? Сохраните.
      </DialogTitle>
      <DialogContent className="install-pwa-info">
        <Box
          component="img"
          className="install-pwa-info-logo"
          src={bucketImage}
          alt="лого"
        />
        <Typography className="install-pwa-info-title">Family List</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsDialogOpened(false)}>Отмена</Button>
        <Button onClick={handleInstallClick}>Сохранить</Button>
      </DialogActions>
    </Dialog>
  );
};
