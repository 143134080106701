import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { useState } from "react";

import { ListInternalModel } from "../../../services/internalStorage/models/ListInternalModel";
import { ShareList } from "../../ShareList/ShareList";
import "./ShareListDialog.scss";

export interface ShareListDialogProps {
  open: boolean;
  onClose: () => void;
  signedIn: boolean;
  onFetchShareKey: (listId: number | null) => void;
  shareListKey: string | null;
  selectedList: ListInternalModel;
}

export const ShareListDialog = (props: ShareListDialogProps) => {
  const [signInButtonClicked, setSignInButtonClicked] = useState(false);

  const handleClose = () => {
    setSignInButtonClicked(false);
    props.onClose();
  };

  return (
    <Dialog open={props.open} onClose={handleClose} className="dialog">
      <DialogTitle className="dialog-title">
        {props.signedIn
          ? "Поделиться списком"
          : "Хотите воспользоваться всеми возможностями?"}
      </DialogTitle>
      <IconButton aria-label="close" onClick={handleClose} className="dialog-close">
        <Close />
      </IconButton>
      <DialogContent>
        {props.signedIn ? (
          <ShareList
            onFetchShareKey={props.onFetchShareKey}
            shareListKey={props.shareListKey}
            selectedList={props.selectedList}
            close={props.onClose}
          />
        ) : (
          signInButtonClicked && <Button className="sign-in-accept">Продолжить</Button>
        )}
      </DialogContent>
      {!props.signedIn && !signInButtonClicked && (
        <DialogActions>
          <Button onClick={() => setSignInButtonClicked(true)}>Войти</Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
