import { Stack } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AlertSnackbar } from "../../../components/AlertSnackbar/AlertSnackbar";
import { AcceptConditionsDialog } from "../../../components/Dialogs/AcceptConditionDialog/AcceptConditionDialog";
import { LoginOptions } from "../../../components/LoginOptions/LoginOptions";
import { LoginQuestion } from "../../../components/LoginQuestion/LoginQuestion";
import { LoginTitle } from "../../../components/LoginTitle/LoginTitle";
import { PageLoading } from "../../../components/PageLoading/PageLoading";
import SwipeableTextMobileStepper from "../../../components/Slider/Slider";
import "./LoginHomePage.scss";
import { loginByGoogle } from "../../../store/auth/authSlice";
import { selectAuthState } from "../../../store/auth/selectors";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { Paths } from "../../../utils/constants";

export const LoginHomePage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { signedIn } = useAppSelector(selectAuthState);
  const [isGoogleLoginError, setIsGoogleLoginError] = useState(false);
  const [isGoogleLoginLoading, setIsGoogleLoginLoading] = useState(false);
  const [isConditionsDialogOpened, setIsConditionsDialogOpened] = useState(false);

  useEffect(() => {
    if (signedIn) {
      navigate("/");
    }
  }, [signedIn, navigate]);

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      setIsGoogleLoginLoading(true);
      const loginAction = await dispatch(loginByGoogle(codeResponse.code));
      setIsGoogleLoginLoading(false);
      if (!loginAction.payload) {
        setIsGoogleLoginError(true);
      }
    },
    onError: async () => {
      setIsGoogleLoginError(true);
    },
    flow: "auth-code",
  });

  const handleAcceptConditions = () => {
    setIsConditionsDialogOpened(false);
    handleGoogleLogin();
  };

  if (signedIn) {
    return null;
  }

  return (
    <Stack className="login-home-container">
      <LoginTitle text="Добро пожаловать!  👋" backPath="/" />
      <Stack alignItems={"center"} position={"relative"} width={"100%"}>
        <SwipeableTextMobileStepper />
        <LoginOptions
          onLoginGoogle={() => setIsConditionsDialogOpened(true)}
          onLoginEmail={() => navigate(Paths.LOGIN)}
        />
        <LoginQuestion
          question="Нет аккаунта?"
          linkText="Регистрация"
          linkAddress={Paths.SIGN_UP}
        />
      </Stack>
      <AlertSnackbar
        open={isGoogleLoginError}
        onClose={() => setIsGoogleLoginError(false)}
      >
        Ошибка авторизации
      </AlertSnackbar>
      {isGoogleLoginLoading && <PageLoading />}
      <AcceptConditionsDialog
        onAccept={handleAcceptConditions}
        open={isConditionsDialogOpened}
        onClose={() => setIsConditionsDialogOpened(false)}
      />
    </Stack>
  );
};
