import { ListItemInternalModel } from "../../services/internalStorage/models/ListItemInternalModel";
import { getDateValue } from "../../utils/dateTimeUtil";
import { RootState } from "../store";

export const selectListsState = (state: RootState) => state.lists;

export const selectSortedByCategoryListItems = (state: RootState) => {
  return [...state.lists.listItems].sort((a, b) => {
    if (!a.localCategory?.id && !b.localCategory?.id) {
      return 0;
    }

    if (!a.localCategory?.id) {
      return 1;
    }

    if (!b.localCategory?.id) {
      return -1;
    }

    if (a.localCategory.id === b.localCategory.id) {
      return a.order - b.order;
    }

    return a.localCategory.id - b.localCategory.id;
  });
};

export const selectCompletedListItems = (state: RootState) => {
  return state.lists.listItems
    .filter((listItem) => listItem.isCompleted)
    .sort((a, b) => getDateValue(a.updated) - getDateValue(b.updated));
};

export const selectGroupedByCategoryListItems = (state: RootState) => {
  const uncompletedListItems = selectSortedByCategoryListItems(state).filter(
    (listItem) => !listItem.isCompleted,
  );
  const listItemsGroupsByCategoryObject = uncompletedListItems.reduce<{
    [categoryLocalName: string]: ListItemInternalModel[];
  }>((acc, listItem) => {
    if (!listItem.localCategory) {
      return acc;
    }
    const localCategoryName = listItem.localCategory.name;
    if (!acc[localCategoryName]) {
      acc[localCategoryName] = [];
    }
    acc[localCategoryName].push(listItem);
    return acc;
  }, {});

  const listItemsGroupsByCategory = Object.values(listItemsGroupsByCategoryObject).filter(
    (group) => group.length > 1,
  );
  const listItemsSingleInCategory = Object.values(listItemsGroupsByCategoryObject)
    .filter((group) => group.length === 1)
    .map((group) => group[0]);
  const listItemsWithoutCategory = uncompletedListItems.filter(
    (listItem) => !listItem.localCategory,
  );

  return [
    ...listItemsGroupsByCategory,
    [...listItemsSingleInCategory, ...listItemsWithoutCategory],
  ]
    .filter((group) => group.length)
    .map((group) =>
      group.sort((a, b) => getDateValue(a.created) - getDateValue(b.created)),
    );
};
