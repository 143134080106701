import { useEffect, useState } from "react";

import { ListItemCategoryInternalModel } from "../../../services/internalStorage/models/ListItemCategoryInternalModel";
import { ListItemInternalModel } from "../../../services/internalStorage/models/ListItemInternalModel";
import { CategoryCreation } from "../../CategoryCreation/CategoryCreation";
import { CategoryEditing } from "../../CategoryEditing/CategoryEditing";
import { CategorySelection } from "../../CategorySelection/CategorySelection";
import { CurtainPopup } from "../../CurtainPopup/CurtainPopup";

export interface EditListItemDialogProps {
  open: boolean;
  onClose: () => void;
  categories: ListItemCategoryInternalModel[];
  onCategoryChange: (newCategory: ListItemCategoryInternalModel | null) => void;
  onCategoryCreate: (name: string, color: string) => void;
  onCategoryEdit: (editedCategory: ListItemCategoryInternalModel) => void;
  listItem?: ListItemInternalModel;
}

enum Pages {
  CREATION = "CREATION",
  SELECTION = "SELECTION",
  EDITING = "EDITING",
}

export const EditListItemDialog = (props: EditListItemDialogProps) => {
  const [currentPage, setCurrentPage] = useState<Pages>(Pages.SELECTION);
  const [categoryToEdit, setCategoryToEdit] = useState<ListItemCategoryInternalModel>();

  const handleEditCategoryClick = (categoryToEdit: ListItemCategoryInternalModel) => {
    setCategoryToEdit(categoryToEdit);
    setCurrentPage(Pages.EDITING);
  };

  useEffect(() => {
    if (props.open) {
      setCurrentPage(Pages.SELECTION);
    }
  }, [props.open]);

  return (
    <CurtainPopup open={props.open} onClose={props.onClose}>
      {currentPage === Pages.SELECTION && (
        <CategorySelection
          categories={props.categories}
          currentListItem={props.listItem}
          onSelect={props.onCategoryChange}
          onCancel={props.onClose}
          reset={props.open}
          onCreateCategoryClick={() => setCurrentPage(Pages.CREATION)}
          onEditCategoryClick={handleEditCategoryClick}
        />
      )}
      {currentPage === Pages.CREATION && (
        <CategoryCreation
          categories={props.categories}
          currentListItem={props.listItem}
          onCategoryCreate={props.onCategoryCreate}
          onBack={() => setCurrentPage(Pages.SELECTION)}
        />
      )}
      {currentPage === Pages.EDITING && categoryToEdit && (
        <CategoryEditing
          categories={props.categories}
          categoryToEdit={categoryToEdit}
          onCategoryEdit={props.onCategoryEdit}
          onBack={() => setCurrentPage(Pages.SELECTION)}
        />
      )}
    </CurtainPopup>
  );
};
