import { IDBPDatabase } from "idb";

import { Stores } from "../core/Stores";
import getDb from "../core/getDb";
import { ListItemPromptInternalModel } from "../models/ListItemPromptInternalModel";

export class InternalStoragePromptsService {
  public static async addOrUpdatePrompts(
    prompts: ListItemPromptInternalModel[],
    dbName?: string,
  ): Promise<void> {
    const db: IDBPDatabase = await getDb(dbName);

    try {
      const tx = db.transaction(Stores.LIST_ITEM_PROMPTS, "readwrite");

      const putPromises = prompts.map((prompt) => tx.store.put(prompt, prompt.localId));
      await Promise.all([...putPromises, tx.done]);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public static async getPrompts(
    dbName?: string,
  ): Promise<ListItemPromptInternalModel[]> {
    const db: IDBPDatabase = await getDb(dbName);

    try {
      return await db.getAll(Stores.LIST_ITEM_PROMPTS);
    } catch (e) {
      return [];
    }
  }
}
