import { IDBPDatabase, openDB } from "idb";

import { LOCAL_STORAGE_KEYS } from "../../../utils/constants";

import { DbNames } from "./DbNames";
import { Stores } from "./Stores";

type DbConnections = {
  [key: string]: Promise<IDBPDatabase>;
};

const dbConnections: DbConnections = {};
const dbVersion = 4;
const dbUpgrades = {
  upgrade(db: IDBPDatabase): void {
    if (!db.objectStoreNames.contains(Stores.LIST_ITEM_CATEGORIES)) {
      db.createObjectStore(Stores.LIST_ITEM_CATEGORIES);
    }
    if (!db.objectStoreNames.contains(Stores.LISTS_STORE)) {
      db.createObjectStore(Stores.LISTS_STORE);
    }
    if (!db.objectStoreNames.contains(Stores.LIST_ITEMS_STORE)) {
      db.createObjectStore(Stores.LIST_ITEMS_STORE);
    }
    if (!db.objectStoreNames.contains(Stores.LIST_ITEM_CATEGORIES)) {
      db.createObjectStore(Stores.LIST_ITEM_CATEGORIES);
    }
    if (!db.objectStoreNames.contains(Stores.LIST_ITEM_PROMPTS)) {
      db.createObjectStore(Stores.LIST_ITEM_PROMPTS);
    }
  },
};

export default function getDb(name?: string): Promise<IDBPDatabase> {
  const dbName =
    name ||
    localStorage.getItem(LOCAL_STORAGE_KEYS.USER_EMAIL) ||
    DbNames.DEFAULT_USER_NAME;
  if (!dbConnections[dbName]) {
    dbConnections[dbName] = openDB(dbName, dbVersion, dbUpgrades);
  }
  return dbConnections[dbName];
}
