import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ListItemCategoryInternalModel } from "../../services/internalStorage/models/ListItemCategoryInternalModel";
import { InternalStorageCategoriesService } from "../../services/internalStorage/services/InternalStorageCategoriesService";
import { InternalStorageListsService } from "../../services/internalStorage/services/InternalStorageListsService";
import { InternalStoragePromptsService } from "../../services/internalStorage/services/InternalStoragePromptsService";
import { SyncCategoriesService } from "../../services/sync/services/SyncCategoriesService";
import { getUniqueId } from "../../utils/dateTimeUtil";
import { RootState } from "../store";

export type CategoriesStateType = {
  categories: ListItemCategoryInternalModel[];
};

const initialState: CategoriesStateType = {
  categories: [],
};

export const syncCategories = createAsyncThunk<void, void>(
  "lists/syncCategories",
  async () => {
    try {
      SyncCategoriesService.enqueue();
    } catch (e) {
      console.log(e);
    }
  },
);

export const fetchCategories = createAsyncThunk<ListItemCategoryInternalModel[] | null>(
  "categories/fetchCategories",
  async () => {
    try {
      return await InternalStorageCategoriesService.getCategories();
    } catch (e) {
      return null;
    }
  },
);

export const addCategory = createAsyncThunk<
  ListItemCategoryInternalModel | null,
  { name: string; color: string }
>("categories/addCategory", async ({ name, color }) => {
  try {
    const newCategory = {
      id: null,
      localId: getUniqueId(),
      name,
      color,
      colorDark: color,
      order: 0,
      created: new Date().toISOString(),
      updated: new Date().toISOString(),
      deleted: null,
    };
    await InternalStorageCategoriesService.addOrUpdateCategories([newCategory]);
    SyncCategoriesService.enqueue();
    return newCategory;
  } catch (e) {
    console.log(e);
    return null;
  }
});

export const updateCategory = createAsyncThunk<
  ListItemCategoryInternalModel | null,
  ListItemCategoryInternalModel
>("categories/updateCategory", async (category: ListItemCategoryInternalModel) => {
  try {
    await InternalStorageCategoriesService.addOrUpdateCategories([category]);
    await InternalStoragePromptsService.addOrUpdatePrompts(
      (await InternalStoragePromptsService.getPrompts())
        .filter((prompt) => prompt.localCategoryId === category.localId)
        .map((prompt) => ({
          ...prompt,
          updated: new Date().toISOString(),
        })),
    );
    await Promise.all(
      (await InternalStorageListsService.getAllListItems())
        .filter((listItem) => listItem.localCategory?.localId === category.localId)
        .map(async (listItem) => {
          await InternalStorageListsService.updateListItem({
            localId: listItem.localId,
            localCategory: category,
            updated: new Date().toISOString(),
          });
        }),
    );
    SyncCategoriesService.enqueue();
    return category;
  } catch (e) {
    console.log(e);
    return null;
  }
});

export const runSyncCategories = createAsyncThunk<void, number>(
  "categories/runSyncCategories",
  async (interval, { dispatch, getState }) => {
    const updateStore = () => {
      dispatch(fetchCategories());
    };
    const checkSignIn = () => {
      const state = getState() as RootState;
      return state.auth.signedIn;
    };
    SyncCategoriesService.run(interval, updateStore, checkSignIn);
  },
);

export const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      if (action.payload) {
        state.categories = action.payload;
      }
    });
    builder.addCase(addCategory.fulfilled, (state, action) => {
      if (action.payload) {
        state.categories.push(action.payload);
      }
    });
    builder.addCase(updateCategory.fulfilled, (state, action) => {
      const updatedCategory = action.payload;
      if (updatedCategory) {
        state.categories = state.categories.map((category) => {
          if (category.localId === updatedCategory.localId) {
            return { ...updatedCategory };
          }
          return category;
        });
      }
    });
  },
});
