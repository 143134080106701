import { DbNames } from "../core/DbNames";
import { Stores } from "../core/Stores";
import getDb from "../core/getDb";
import { ListInternalModel, ListUpdateParams } from "../models/ListInternalModel";
import {
  ListItemInternalModel,
  ListItemUpdateParams,
} from "../models/ListItemInternalModel";

export class InternalStorageListsService {
  public static async getLists(dbName?: string): Promise<ListInternalModel[]> {
    try {
      const db = await getDb(dbName);
      return await db.getAll(Stores.LISTS_STORE);
    } catch (e) {
      console.log(
        `InternalStorageListsService.getLists --> ${e instanceof Error && e.message}`,
      );
      throw e;
    }
  }

  public static async getListItems(
    listId: number,
    dbName?: string,
  ): Promise<ListItemInternalModel[]> {
    try {
      const db = await getDb(dbName);
      const allListItems: ListItemInternalModel[] = await db.getAll(
        Stores.LIST_ITEMS_STORE,
      );
      return allListItems
        .filter((listItem: ListItemInternalModel): boolean => {
          return listItem.localListId === listId;
        })
        .sort((a, b) => a.order - b.order);
    } catch (e) {
      console.log(
        `InternalStorageListsService.getListItems --> ${e instanceof Error && e.message}`,
      );
      throw e;
    }
  }

  public static async getAllListItems(dbName?: string): Promise<ListItemInternalModel[]> {
    try {
      const db = await getDb(dbName);
      return await db.getAll(Stores.LIST_ITEMS_STORE);
    } catch (e) {
      console.log(
        `InternalStorageListsService.getAllListItems --> ${e instanceof Error && e.message}`,
      );
      throw e;
    }
  }

  public static async createList(
    list: ListInternalModel,
    dbName?: string,
  ): Promise<ListInternalModel> {
    try {
      const db = await getDb(dbName);
      const tx = db.transaction(Stores.LISTS_STORE, "readwrite");
      await Promise.all([tx.store.add(list, list.localId), tx.done]);
      return await db.get(Stores.LISTS_STORE, list.localId);
    } catch (e) {
      console.log(
        `InternalStorageListsService.createList --> ${e instanceof Error && e.message}`,
      );
      throw e;
    }
  }

  public static async updateList(
    listUpdateParams: ListUpdateParams,
    dbName?: string,
  ): Promise<ListInternalModel> {
    try {
      const db = await getDb(dbName);
      const oldList: ListInternalModel = await db.get(
        Stores.LISTS_STORE,
        listUpdateParams.localId,
      );
      const tx = db.transaction(Stores.LISTS_STORE, "readwrite");
      await Promise.all([
        tx.store.put(
          { ...oldList, updated: new Date().toISOString(), ...listUpdateParams },
          listUpdateParams.localId,
        ),
        tx.done,
      ]);
      return await db.get(Stores.LISTS_STORE, listUpdateParams.localId);
    } catch (e) {
      console.log(
        `InternalStorageListsService.updateList --> ${e instanceof Error && e.message}`,
      );
      throw e;
    }
  }

  public static async deleteList(
    localListId: number,
    soft: boolean,
    dbName?: string,
  ): Promise<number> {
    try {
      const db = await getDb(dbName);
      const list: ListInternalModel = await db.get(Stores.LISTS_STORE, localListId);
      const linkedListItems: ListItemInternalModel[] = (
        await db.getAll(Stores.LIST_ITEMS_STORE)
      ).filter(
        (listItem: ListItemInternalModel): boolean =>
          listItem.localListId === localListId,
      );
      const date = new Date();
      let promises;
      if (soft) {
        promises = linkedListItems.map((listItem: ListItemInternalModel) =>
          db.put(
            Stores.LIST_ITEMS_STORE,
            { ...listItem, deleted: date.toISOString() },
            listItem.localId,
          ),
        );
        promises.push(
          db.put(
            Stores.LISTS_STORE,
            { ...list, deleted: date.toISOString() },
            list.localId,
          ),
        );
      } else {
        promises = linkedListItems.map((listItem: ListItemInternalModel) =>
          db.delete(Stores.LIST_ITEMS_STORE, listItem.localId),
        );
        promises.push(db.delete(Stores.LISTS_STORE, list.localId));
      }
      await Promise.all(promises);
      return localListId;
    } catch (e) {
      console.log(
        `InternalStorageListsService.deleteList --> ${e instanceof Error && e.message}`,
      );
      throw e;
    }
  }

  public static async createListItem(
    listItem: ListItemInternalModel,
    dbName?: string,
  ): Promise<ListItemInternalModel> {
    try {
      const db = await getDb(dbName);
      const tx = db.transaction(Stores.LIST_ITEMS_STORE, "readwrite");
      await Promise.all([tx.store.add(listItem, listItem.localId), tx.done]);
      return await db.get(Stores.LIST_ITEMS_STORE, listItem.localId);
    } catch (e) {
      console.log(
        `InternalStorageListsService.createListItem --> ${e instanceof Error && e.message}`,
      );
      throw e;
    }
  }

  public static async updateListItem(
    listItemUpdateParams: ListItemUpdateParams,
    dbName?: string,
  ): Promise<ListItemInternalModel> {
    try {
      const db = await getDb(dbName);
      const oldListItem: ListItemInternalModel = await db.get(
        Stores.LIST_ITEMS_STORE,
        listItemUpdateParams.localId,
      );
      const tx = db.transaction(Stores.LIST_ITEMS_STORE, "readwrite");
      await Promise.all([
        tx.store.put(
          { ...oldListItem, updated: new Date().toISOString(), ...listItemUpdateParams },
          listItemUpdateParams.localId,
        ),
        tx.done,
      ]);
      return await db.get(Stores.LIST_ITEMS_STORE, listItemUpdateParams.localId);
    } catch (e) {
      console.log(
        `InternalStorageListsService.updateListItem --> ${e instanceof Error && e.message}`,
      );
      throw e;
    }
  }

  public static async deleteListItem(
    listItemLocalId: number,
    soft: boolean,
    dbName?: string,
  ): Promise<number> {
    try {
      const db = await getDb(dbName);
      const listItem: ListItemInternalModel = await db.get(
        Stores.LIST_ITEMS_STORE,
        listItemLocalId,
      );
      const tx = db.transaction(Stores.LIST_ITEMS_STORE, "readwrite");
      if (soft) {
        await Promise.all([
          tx.store.put(
            { ...listItem, deleted: new Date().toISOString() },
            listItemLocalId,
          ),
          tx.done,
        ]);
      } else {
        await Promise.all([tx.store.delete(listItemLocalId), tx.done]);
      }
      return listItemLocalId;
    } catch (e) {
      console.log(
        `InternalStorageListsService.deleteListItem --> ${e instanceof Error && e.message}`,
      );
      throw e;
    }
  }

  public static async transferAnonymousDataToLoggedInUserAccount(): Promise<void> {
    try {
      const anonymousLists = await this.getLists(DbNames.DEFAULT_USER_NAME);
      const anonymousListsItems = await this.getAllListItems(DbNames.DEFAULT_USER_NAME);

      const deleteListsPromises = anonymousLists.map((list) =>
        this.deleteList(list.localId, false, DbNames.DEFAULT_USER_NAME),
      );
      const deleteListsItemsPromises = anonymousListsItems.map((listItem) =>
        this.deleteListItem(listItem.localId, false, DbNames.DEFAULT_USER_NAME),
      );
      const createListsPromises = anonymousLists
        .filter((list) => !list.deleted)
        .map((list) => this.createList({ ...list }));
      const createListsItemsPromises = anonymousListsItems
        .filter((listItem) => !listItem.deleted)
        .map((listItem) => this.createListItem({ ...listItem }));
      await Promise.all(createListsPromises);
      await Promise.all(createListsItemsPromises);
      await Promise.all(deleteListsPromises);
      await Promise.all(deleteListsItemsPromises);
    } catch (e) {
      console.log(
        `InternalStorageListsService.transferAnonymousDataToLoggedInUserAccount -->
        ${e instanceof Error && e.message}`,
      );
    }
  }
}
