import { IDBPDatabase } from "idb";

import { Stores } from "../core/Stores";
import getDb from "../core/getDb";
import { ListItemCategoryInternalModel } from "../models/ListItemCategoryInternalModel";

export class InternalStorageCategoriesService {
  public static async addOrUpdateCategories(
    categories: ListItemCategoryInternalModel[],
    dbName?: string,
  ): Promise<void> {
    const db: IDBPDatabase = await getDb(dbName);

    try {
      const tx = db.transaction(Stores.LIST_ITEM_CATEGORIES, "readwrite");
      const putPromises = categories.map((category) => {
        return tx.store.put(category, category.localId);
      });
      await Promise.all([...putPromises, tx.done]);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public static async getCategories(
    dbName?: string,
  ): Promise<ListItemCategoryInternalModel[]> {
    const db: IDBPDatabase = await getDb(dbName);

    try {
      return await db.getAll(Stores.LIST_ITEM_CATEGORIES);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public static async getCategoryByLocalId(
    categoryLocalId: number,
  ): Promise<ListItemCategoryInternalModel> {
    const db: IDBPDatabase = await getDb();

    try {
      return await db.get(Stores.LIST_ITEM_CATEGORIES, categoryLocalId);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public static async deleteCategoriesByLocalIds(
    categoriesLocalIds: number[],
    dbName?: string,
  ): Promise<void> {
    const db: IDBPDatabase = await getDb(dbName);
    try {
      const tx = db.transaction(Stores.LIST_ITEM_CATEGORIES, "readwrite");
      const deletePromises = categoriesLocalIds.map((localId) => {
        return tx.store.delete(localId);
      });
      await Promise.all([...deletePromises, tx.done]);
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
