import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ListItemPromptInternalModel } from "../../services/internalStorage/models/ListItemPromptInternalModel";
import { InternalStoragePromptsService } from "../../services/internalStorage/services/InternalStoragePromptsService";
import { SyncPromptsService } from "../../services/sync/services/SyncPromptsService";
import { RootState } from "../store";

export type PromptsStateType = {
  prompts: ListItemPromptInternalModel[];
};

const initialState: PromptsStateType = {
  prompts: [],
};

export const syncPrompts = createAsyncThunk<void, void>("lists/syncPrompts", async () => {
  try {
    SyncPromptsService.enqueue();
  } catch (e) {
    console.log(e);
  }
});

export const fetchPrompts = createAsyncThunk<Array<ListItemPromptInternalModel>>(
  "prompts/fetchPrompts",
  async () => {
    try {
      return await InternalStoragePromptsService.getPrompts();
    } catch (e) {
      return [];
    }
  },
);

export const addPrompt = createAsyncThunk<
  ListItemPromptInternalModel | null,
  { name: string; localCategoryId: number }
>("prompts/addPrompt", async ({ name, localCategoryId }) => {
  try {
    const newPrompt = {
      localId: name,
      name,
      order: 0,
      localCategoryId,
      created: new Date().toISOString(),
      updated: new Date().toISOString(),
    };
    await InternalStoragePromptsService.addOrUpdatePrompts([newPrompt]);
    SyncPromptsService.enqueue();
    return newPrompt;
  } catch (e) {
    console.log(e);
    return null;
  }
});

export const updatePrompt = createAsyncThunk<
  ListItemPromptInternalModel | null,
  ListItemPromptInternalModel
>("prompts/updatePrompt", async (prompt: ListItemPromptInternalModel) => {
  try {
    await InternalStoragePromptsService.addOrUpdatePrompts([prompt]);
    SyncPromptsService.enqueue();
    return prompt;
  } catch (e) {
    console.log(e);
    return null;
  }
});

export const runSyncPrompts = createAsyncThunk<void, number>(
  "prompts/runSyncPrompts",
  async (interval, { dispatch, getState }) => {
    const updateStore = () => {
      dispatch(fetchPrompts());
    };
    const checkSignIn = () => {
      const state = getState() as RootState;
      return state.auth.signedIn;
    };
    SyncPromptsService.run(interval, updateStore, checkSignIn);
  },
);

export const promptsSlice = createSlice({
  name: "prompts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPrompts.fulfilled, (state, action) => {
      if (action.payload) {
        state.prompts = action.payload;
      }
    });
    builder.addCase(addPrompt.fulfilled, (state, action) => {
      if (action.payload) {
        state.prompts.push(action.payload);
      }
    });
    builder.addCase(updatePrompt.fulfilled, (state, action) => {
      const updatedPrompt = action.payload;
      if (updatedPrompt) {
        state.prompts = state.prompts.map((prompt) => {
          if (prompt.localId === updatedPrompt.localId) {
            return { ...updatedPrompt };
          }
          return prompt;
        });
      }
    });
  },
});
