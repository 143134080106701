import { LOCAL_STORAGE_KEYS } from "../../utils/constants";
import { User } from "../openapi";
import * as api from "../openapi/services/AuthService";

export default class AuthService {
  static async getAccessToken(): Promise<string | null> {
    return window.localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  }

  static async loginByGoogle(googleCode: string): Promise<string> {
    const token = await api.AuthService.postApiAuthGoogleLogin({ authCode: googleCode });
    window.localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, token);
    return token;
  }

  static async loginByEmail(email: string, password: string): Promise<string> {
    const token = await api.AuthService.postApiAuthLogin({ email, password });
    window.localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, token);
    return token;
  }

  static async signUp(email: string, password: string): Promise<string> {
    const token = await api.AuthService.postApiAuthSignUp({ email, password });
    window.localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, token);
    return token;
  }

  static async getAndSaveUserProfile(): Promise<User> {
    const result = await api.AuthService.getApiAuthProfile();
    window.localStorage.setItem(
      LOCAL_STORAGE_KEYS.USER_NAME,
      result.name || "Не залогиненный пользователь",
    );
    window.localStorage.setItem(
      LOCAL_STORAGE_KEYS.USER_LOGO_URL,
      result.profileUrl || "",
    );
    window.localStorage.setItem(LOCAL_STORAGE_KEYS.USER_EMAIL, result.email || "");
    return result;
  }

  static async logoutCleanup() {
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_NAME);
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_LOGO_URL);
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_EMAIL);
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.INSTALL_BUTTON_LAST_SHOW);
  }
}
