import { Stack } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import "./SuopportPage.scss";

import { BaseButton } from "../../../components/BaseButton/BaseButton";
import { LoginInput } from "../../../components/LoginInput/LoginInput";
import { LoginTitle } from "../../../components/LoginTitle/LoginTitle";
import { SupportService } from "../../../services/openapi";
import { emailRegex } from "../../../utils/constants";

export const SupportPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [showError, setShowError] = useState(false);

  const navigate = useNavigate();
  return (
    <Stack className="support-container">
      <LoginTitle text="Обратная связь" backPath="/" />
      <Stack className="support-input-container">
        <LoginInput
          type="text"
          name="Ваше имя"
          onChange={setName}
          value={name}
          isError={showError && name.length == 0}
          errorText="Обязательно для заполнения"
        />
        <LoginInput
          type="text"
          name="Ваш адрес электронной почты"
          onChange={setEmail}
          value={email}
          isError={showError && !emailRegex.test(email)}
          errorText="Email должен быть формата test@test.ru"
        />
        <Stack className="support-text-area">
          <LoginInput
            type="text-area"
            name="Сообщение"
            onChange={(val) => {
              if (message.length < 500) {
                setMessage(val);
              }
            }}
            value={message}
            isError={showError && message.length == 0}
            errorText="Обязательно для заполнения"
          />
          <span className="support-text-count">
            Введено {message.length} из 500 символов
          </span>
        </Stack>
      </Stack>

      <BaseButton
        onClick={async () => {
          if (name.length == 0 || message.length == 0 || !emailRegex.test(email)) {
            setShowError(true);
            return;
          }
          await SupportService.postApiSupport({
            email: email,
            name: name,
            text: message,
          });
          navigate("/");
        }}
        className="support-button"
      >
        Отправить
      </BaseButton>
    </Stack>
  );
};
